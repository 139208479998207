import { useEffect, useState } from "react";

import {
  getDocumentationUrl,
  getLandingUrl,
  getLoginUrl,
  getRegisterUrl,
} from "../../../utils/UrlUtils";
import MobileMenu from "./MobileMenu";
import Button from "./utils/Button";

export default function Header() {
  const [top, setTop] = useState<boolean>(true);

  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  function handleLogin(): void {
    window.location.href = getLoginUrl();
  }

  function handleSignup(): void {
    window.location.href = getRegisterUrl();
  }

  function handleOpenDocs(): void {
    window.open(getDocumentationUrl(), "_blank");
  }

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top ? "bg-gray-100 backdrop-blur-sm shadow-lg" : ""
      }`}
    >
      <div className='max-w-6xl mx-auto px-5 sm:px-6'>
        <div className='flex items-center justify-between h-16 md:h-20'>
          {/* Site branding */}
          <div className='shrink-0 mr-4'>
            <a href={getLandingUrl()} className='text-2xl font-bold'>
              DynamoPDF
            </a>
          </div>

          {/* Desktop navigation */}
          <nav className='hidden md:flex md:grow'>
            {/* Desktop sign in links */}
            <ul className='flex grow justify-end flex-wrap items-center'>
              <li>
                <Button
                  id='header-documentation'
                  text='Documentation'
                  color='transparent'
                  size='regular'
                  onClick={handleOpenDocs}
                />
              </li>
              <li>
                <Button
                  id='header-login'
                  text='Sign in'
                  color='transparent'
                  size='regular'
                  className='ml-3'
                  onClick={handleLogin}
                />
              </li>
              <li>
                <Button
                  id='header-register'
                  text='Sign up'
                  color='black'
                  size='regular'
                  iconClass='bi-arrow-right float-right ml-2'
                  className='ml-3'
                  onClick={handleSignup}
                />
              </li>
            </ul>
          </nav>

          <MobileMenu />
        </div>
      </div>
    </header>
  );
}
