import {
  getContactUrl,
  getCookiePolicyUrl,
  getDocumentationUrl,
  getLandingUrl,
  getPricingUrl,
  getPrivacyPolicyUrl,
  getStatusPageUrl,
  getTermsUrl,
} from "../../../utils/UrlUtils";
import { Logo } from "./Logo";

export const Footer = () => {
  return (
    <footer>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        {/* Top area: Blocks */}
        <div className='grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200'>
          {/* 1st block */}
          <div className='sm:col-span-12 lg:col-span-3'>
            <div className='mb-2'>
              <Logo />
            </div>
            <div className='text-sm text-gray-600'>DynamoPDF.com</div>
          </div>

          {/* Resources */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-3'>
            <h6 className='text-gray-800 font-medium mb-2'>Resources</h6>
            <ul className='text-sm'>
              <li className='mb-2'>
                <a
                  id='footer-documentation'
                  href={getDocumentationUrl()}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Documentation
                </a>
              </li>
              <li className='mb-2'>
                <a
                  id='footer-generate-first-pdf'
                  href='https://dynamopdf.readme.io/reference/generate-your-first-pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Generate your first PDF
                </a>
              </li>
              <li className='mb-2'>
                <a
                  id='footer-status'
                  href={getStatusPageUrl()}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Status
                </a>
              </li>
            </ul>
          </div>

          {/* Company */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-3'>
            <h6 className='text-gray-800 font-medium mb-2'>Company</h6>
            <ul className='text-sm'>
              <li className='mb-2'>
                <a
                  id='footer-home'
                  href={getLandingUrl()}
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Home
                </a>
              </li>
              <li className='mb-2'>
                <a
                  id='footer-pricing'
                  href={getPricingUrl()}
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Pricing
                </a>
              </li>
              <li className='mb-2'>
                <a
                  id='footer-contact'
                  href={getContactUrl()}
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Contact us
                </a>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-3'>
            <h6 className='text-gray-800 font-medium mb-2'>Legal</h6>
            <ul className='text-sm'>
              <li className='mb-2'>
                <a
                  id='footer-terms'
                  href={getTermsUrl()}
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Terms of service
                </a>
              </li>
              <li className='mb-2'>
                <a
                  id='footer-privacy'
                  href={getPrivacyPolicyUrl()}
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Privacy policy
                </a>
              </li>
              <li className='mb-2'>
                <a
                  id='footer-cookies'
                  href={getCookiePolicyUrl()}
                  className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  Cookie policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
