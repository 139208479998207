import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";

import {
  getDocumentationUrl,
  getLoginUrl,
  getRegisterUrl,
} from "../../../utils/UrlUtils";

export const MobileMenu = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const mobileNav = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }): void => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  function handleOpenDocs() {
    window.open(getDocumentationUrl(), "_blank");
  }

  return (
    <div className='flex md:hidden'>
      <a
        id='menu-toggle'
        className={`cursor-pointer menu-toggle ${
          mobileNavOpen ? "nav-open" : ""
        }`}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className='menu-toggle-bar menu-toggle-bar--top'></span>
        <span className='menu-toggle-bar menu-toggle-bar--middle'></span>
        <span className='menu-toggle-bar menu-toggle-bar--bottom'></span>
      </a>

      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          as='nav'
          id='mobile-nav'
          className='absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-gray-100'
          enter='transition ease-out duration-200 transform'
          enterFrom='opacity-0 -translate-y-2'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-out duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <ul className='px-5 py-2'>
            <li>
              <a
                href='#'
                className='flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center mb-5'
                onClick={handleOpenDocs}
              >
                Documentation
              </a>
            </li>
            <li>
              <a
                href={getLoginUrl()}
                className='flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center'
                onClick={() => setMobileNavOpen(false)}
              >
                Sign in
              </a>
            </li>
            <li>
              <a
                href={getRegisterUrl()}
                className='btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2'
                onClick={() => setMobileNavOpen(false)}
              >
                <span>Sign up</span>
                <svg
                  className='w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1'
                  viewBox='0 0 12 12'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                    fill='#999'
                    fillRule='nonzero'
                  />
                </svg>
              </a>
            </li>
          </ul>
        </Transition>
      </div>
    </div>
  );
};
export default MobileMenu;
